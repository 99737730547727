import axios from '../api/axios';
import './PowerRankings.css';
import React, { useState, useEffect } from 'react';

function PowerRankings() {
  const [rankings, setRankings] = useState([]);

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        const response = await axios.get('https://cuwiffleball-290d16e38d6e.herokuapp.com/api/stats/power-rankings');
        
        // Sort the teams by Elo rating in descending order
        const sortedRankings = response.data.sort((a, b) => b.eloRating - a.eloRating);
        
        setRankings(sortedRankings);
      } catch (err) {
        console.error('Error fetching power rankings:', err);
      }
    };
    fetchRankings();
  }, []);

  return (
    <div className="table-power">
      <h2>Power Rankings</h2>
      {rankings.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Team Name</th>
              <th>Wins</th>
              <th>Losses</th>
              <th>League</th>
            </tr>
          </thead>
          <tbody>
            {rankings.map((team, index) => (
              <tr key={team.teamName}>
                <td>{index + 1}</td>
                <td>{team.teamName}</td>
                <td>{team.wins}</td>
                <td>{team.losses}</td>
                <td>{team.day}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No teams available</p>
      )}
    </div>
  );
}

export default PowerRankings;
